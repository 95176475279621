import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import {graphql, useStaticQuery} from 'gatsby';

import Software from '../../layouts/Software';

const SoftwarePageEn = () => {
  const data = useStaticQuery(graphql`
    query softwareDatasEn {
      image: file(relativePath: { eq: "software/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allContentfulGroupeDeFonctionnalitesLogiciel(filter: {node_locale: {eq: "en-CA"}}) {
        edges {
          node {
            name
            functionalities {
              id
              name
            }
            gif {
              file {
                url
              }
            }
          }
        }
      }
      allContentfulFonctionnaliteLogiciel(filter: {icon: {regex: ""}, node_locale: {eq: "en-CA"}}) {
        edges {
          node {
            name
            icon
          }
        }
      }
    }
  `)

  return (
    <Layout 
    lang="en-CA" 
    switchLink='/logiciel' 
    slider={true} 
    headerImage={data.image.childImageSharp} 
    title="The industry's most user-friendly software" 
    subTitle="Digital signage allows you to communicate in an easy, efficient, and creative way with your customers as well as your staff." 
    anchorLink="#software"
    anchorLinkText="Learn more">
      <Seo title="Digital Signage Software | Digital Signage Management System"
           description="Our digital signage software focused on content and accessible from anywhere allows you to manage the display on each of your screens simply and intuitively. Attractif is an all-in-one digital signage management system."
           lang="en-CA"/>
      <Software lang="en-CA"  functionalitiesGroup={data.allContentfulGroupeDeFonctionnalitesLogiciel} functionalities={data.allContentfulFonctionnaliteLogiciel} />
    </Layout>
  )
}

export default SoftwarePageEn
